'use client';

import { Theme } from '@/models/Models';
import ErrorBlock from '@/modules/errorBlock/ErrorBlock';
import { useEffect } from 'react';

const Error = ({ error }: { error: Error }) => {
  useEffect(() => {
    // eslint-disable-next-line no-console
    console.error(error);
  }, [error]);

  return <ErrorBlock theme={Theme.Dark} />;
};

export default Error;
